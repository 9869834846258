
          @import "@/assets/css/vars.scss";
        

.base-button {
  font-family: 'Inter';
  display: flex;
  align-items: center;
  justify-content: center;
  // gap: 0 .8em;
  @include flex-gap(.8em, 'row nowrap');
  padding: .8em 1.2em;
  color: white;
  background-color: $main-blue;
  border-radius: 6px;
  border: 2px solid $main-blue;
  cursor: pointer;
  font-weight: 600;
  width: max-content;
  .left-icon,
  .label {
    font-size: .9em;
    display: flex;
    align-items: center;
  }
  .left-icon {
    img.white {
      filter: brightness(5);
    }
  }
  &.aqua {
    background-color: $button-color-aqua;
    border-color: $button-color-aqua;
    color: #172856;
  }
  &.no-fill-color {
    border: 2px solid $main-blue;
    color: $main-blue;
    background-color: transparent;
  }
  &.transparent {
    border: 2px solid transparent;
    background-color: transparent;
    color: #838798;
  }
  &.disabled {
    border: 2px solid $button-background-disabled;
    background-color: $button-background-disabled;
    color: $button-text-disabled;
    cursor: default;
  }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .base-button {
    .left-icon,
    .label {
      margin-bottom: 1px;
    }
  }
}}
