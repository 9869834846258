
          @import "@/assets/css/vars.scss";
        

.lead-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1em 1em 1em 4px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  &.is-unread {
    border-left: 5px solid #F95F83;
    padding: 1em 0;
  }
  h3, p {
    margin: 0;
  }
  > * {
    padding: 0 1vw;
    height: 100%;
  }
  h3 {
    display: flex;
    align-items: center;
    // gap: 0 .4em;
    @include flex-gap(.35em, 'row nowrap');
    span {
      font-size: 1.3em;
    }
    img {
      margin-top: 2px;
      width: 1em;
      height: 1em;
    }
  }
  .status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 8em;
    width: 35%;
    // gap: .2em 0;
    @include flex-gap(.2em, 'column nowrap');
    .date {
      margin-left: 1.4em;
      font-size: .95em;
      color: #9093AE;
      font-weight: 600;
    }
  }
  .type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 40%;
    flex-grow: 1;
    // width: 12em;
    border-left: 1px solid #E6E7EB;
    // gap: .2em 0;
    @include flex-gap(.15em, 'column nowrap');
    .ins-type {
      font-size: 1.5em;
    }
    p {
      font-size: .95em;
      font-weight: 600;
      color: #9093AE;
    }
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // flex-grow: 1;
    width: 25%;
    button {
      // padding: 1vw;
      height: max-content;
      width: 10em;
    }
  }

  .note {
    height: 20px;
    cursor: pointer;
  }
}

@media (max-width: 400px) {
  .lead-wrap {
    .status {
      width: auto;
      h3 {
        flex-direction: column;
      }
      .date {
        margin: 0;
      }
    }
    .type {
      width: 20%;
      div {
        display: flex;
        flex-direction: column;
      }
    }
    .button-wrap {
      width: 35%;
      button {
        padding: .5em 0;
      }
    }
  }
}
