
          @import "@/assets/css/vars.scss";
        

.app-summary-wrap {
  $summary-input-width: min(25em, 100%); 
  display: flex;
  flex-direction: column;
  // @include flex-gap(1em, 'column nowrap');
  background-color: #FFF;
  height: max-content;
  max-height: 83vh;  
  width: 400px;
  min-width: calc(#{$summary-input-width} * 2 + 6em + 20px);
  // max-width: 100%;
  padding: 0 2em 1.3em 2em;
  border-radius: 10px;
  border: 1px solid #F3F3F3;
  // margin-top: .05em;
  overflow: auto;
  position: sticky;
  top: 2em;
  .form {
    display: flex;
    flex-direction: column;
    // padding: 1.5em 2em;
    // border-bottom: 2px solid #DEDEE9;
    box-sizing: border-box;
    border-radius: 6px;
    h3 {
      display: flex;
      align-items: center;
      // gap: min(0.5em, 1vw);
      @include flex-gap(.5em, 'row nowrap');
      span {
        &.home {
          color: $button-color-aqua;
        }
        &.life {
          color: #654BE4;
        }
        &.auto {
          color: $main-blue;
        }
      }
    }
    .inputs {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      // gap: 1.5em;
      @include flex-gap(1.5em, 'row wrap');
      > * {
        width: min(25em, 100%);
      }
    }
  }
}

@media (max-width: 1300px) {
  .app-summary-wrap {
    width: 100%;
    min-width: auto;
  }
}
