
          @import "@/assets/css/vars.scss";
        

.questionmark-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 17px;
    min-width: 17px;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(29, 99, 186, 0.11);
    .text-container-abs {
        position: fixed;
        // position: absolute;
        // top: calc(100% + 10px);
        // left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5em 1em;
        color: white;
        background: $main-blue;
        width: max-content;
        max-width: 25em;
        border-radius: 3px;
        z-index: 5;
        transform: translateX(-50%);
        .triangle {
          $size: 10px;
          width: $size;
          height: $size;
          position: absolute;
          // top: calc(50% - (#{$size} / 2));
          // left: calc(0% - (#{$size} / 2));
          // top: calc(0 - (#{$size} / 2));
          top: -3px;
          right: calc(50% - #{$size} - 2.5px);
          transform: rotate(45deg);
          background: $main-blue;
        }
    }
    .questionmark {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
// fold support
@media screen and (max-width: 280px) {
  .questionmark-wrapper{
    .text-container-abs{
      width: 260px;
    }
  }
}
