
          @import "@/assets/css/vars.scss";
        

* {
  font-family: 'Open Sans';
}
.base-type-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: $input-width;
  // gap: .5em 0;
  @include flex-gap(.5em, 'column nowrap');
  // only if multipleselect
  .multiple-select {
    display: flex;
    flex-direction: column;
    // gap: .5em;
    @include flex-gap(.5em, 'column nowrap');
    min-height: $input-height;
    border-radius: .5em;
    // border: 2px solid #DEDEE9;
    .selected-options {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
        @include flex-gap(.5em, 'row wrap');
      // padding: 1em;
      .option-badge {
        display: flex;
        // gap: .5em;
        // @include flex-gap(1em, 'row nowrap');
        height: max-content;
        border-radius: 6px;
        background: $main-blue;
        color: #FFF;
        padding: 2px .5em;
        span {
          cursor: pointer;
          font-weight: 600;
          display: inline-block;
          margin: 0 0 0 .5em;
        }
      }
    }
    .base-type-dropdown-input {
      height: $input-height;
      width: 100%;
      &.is-open {
        border-radius: .5em .5em 0 0;
      }
      &:disabled {
      border: none;
      background: #EFF0F1;
      &:active {
        outline: none;
      }
    }
    }
  }
  .base-label-el {
    display: flex;
    align-items: center;
    // gap: 1em;
    @include flex-gap(.1em, 'row nowrap');
    font-size: $input-label-font-size;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
    width: max-content;
    margin-bottom: .75em;
  }
  .base-type-dropdown-input {
    height: $input-height;
    padding: 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    color: #434961;
    transition: all .2s ease;
    &::placeholder {
      font-weight: 400;
      color: #9CA0AF;
    }
    &.error {
      border-color: $input-error-border-color;
      margin-bottom: .8em;
      &:active, &:focus {
        border: 2px solid $input-error-border-color;
      }
    }
    &:active, &:focus {
      border: 2px solid #3E94FF;
    }
    &.is-open {
      border-radius: .5em .5em  0 0;
    }
    &:disabled {
      border: none;
      background: #EFF0F1;
      &:active {
        outline: none;
      }
    }
  }
  .options {
    padding: .5em 0;
    position: absolute;
    width: 100%;
    background: #FFF;
    border-radius: 0 0 11px 11px;
    top: calc(100% - 1em);
    border: 1px solid $box-border-color;
    border-top: none;
    z-index: 2;
    overflow: auto;
    max-height: 10em;
    &.multiple {
      top: calc(#{$input-height} + 1.2em);
    }
    .option {
      padding: 0 .5em;
      width: 100%;
      cursor: pointer;
      &:hover {
        color: #FFF;
        background: $main-blue;
        transition: all .2s ease;
      }
      &.selected {
        background: #CECEE1;
      }
    }
  }
  .x {
    user-select: none;
    position: absolute;
    top: .3em;
    right: 1em;
    cursor: pointer;
    //arrow styles
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../assets/icons/select-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(50%);
    background-position-y: -.2em;
    background-color: #FFF;
    //arrow
    height: 15px;
    width: 15px;
    transition: all .2s;
    &.has-label {
      top: 3.45em;
    }
    &.is-open {
      transform: rotate(180deg);
      top: 2.9em;
    }
  }
}
