
          @import "@/assets/css/vars.scss";
        

.base-input {
  * {
    font-family: 'Open Sans';
  }
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: $input-width;
  @include flex-gap(.5em, 'column nowrap');
  //Autofill styles
  .base-input-el:-webkit-autofill,
  .base-input-el:-webkit-autofill:hover,
  .base-input-el:-webkit-autofill:focus,
  .base-input-el:-webkit-autofill:active {
    transition: background-color ease-in-out 5000s;
    -webkit-text-fill-color: #656B7E;
    &.single-line {
      -webkit-text-fill-color: #FFF;
    }
  }
  .base-label-el {
    display: flex;
    align-items: center;
    @include flex-gap(0, 'row nowrap');
    font-size: $input-label-font-size;
    font-weight: 600;
    color: #656B7E;
    width: max-content;
    max-width: 100%;
  }
  .front-symbol{
    position: absolute;
    left: 10px;
    bottom: 6px;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: #434961;
  }
  .back-symbol{
    position: absolute;
    right: 10px;
    bottom: 25%;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: #434961;
  }
  .base-input-el {
    height: $input-height;
    padding: 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    color: #434961;
    transition: all .2s ease;
    &::placeholder {
      font-weight: 400;
      color: #9CA0AF;
    }
    &.error {
      border-color: $input-error-border-color;
      &:active, &:focus {
        border: 2px solid $input-error-border-color;
      }
    }
    &:active, &:focus {
      border: 2px solid $main-blue;
    }
    &:disabled {
      border: none;
      background: #EFF0F1;
      &:active {
        outline: none;
      }
    }
    // Styles for underline input
    &.single-line {
      border-top: none;
      border-left: none;
      border-right: none;
      border-color: rgba(255, 255, 255, .5);
      border-width: 1px;
      background: transparent;
      border-radius: 0;
      padding: 0;
      padding-top: 10px;
      color: #FFF;
      &::placeholder {
        color: #FFF;
      }
      &:active, &:focus {
        color: $input-single-line-color;
        border-color: $main-blue;
      }
      &.error {
        border-color: $input-error-border-color;
        &:active, &:focus {
          border: none;
          border-bottom: 1px solid $input-error-border-color;
        }
      }
    }
  }
  .back-indent{
    padding-right: 3em;
  }
  .front-indent{
    text-indent: 10px;
  }
  .underline-label {
    position: absolute;
    bottom: -2em;
    margin: 0;
    font-style: italic;
    color: rgba(255, 255, 255, .5);
    font-size: .8em;
  }
  .details {
    position: absolute;
    top: 80%;
    left: 40%;
    width: 60%;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.1em;
    border-left: 4px solid $main-blue;
    border-radius: 0 0 .85em 0;
    font-size: .8em;
    font-style: italic;
    font-weight: 600;
    color: #787D8E;
    box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.06);
    z-index: 2;
  }
}

@media screen and (max-width: 1800px) {
  .base-input{
    .front-symbol{
      bottom: 6px;
    }
  }
}

@media screen and (max-width: 280px){
  .base-input{
    .base-label-el{
      width: 80%;
      word-wrap: break-word;
    }
  }
}
