
          @import "@/assets/css/vars.scss";
        

#choose-card-wrap {
  display: flex;
  flex-direction: column;
  .base-radio-group {
    margin: 2em 0;
  }
  h1 {
    margin: 0;
    font-size: 1.3em;
  }
  .cards-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    // > * {
    //   width: max-content;
    // }
  }
  .button-line {
    display: flex;
    justify-content: center;
    .base-button {
      margin-top: 2em;
    }
    p {
      margin: 0;
      color: $main-blue;
      cursor: pointer;
      text-decoration: underline;
      width: max-content;
    }
  }
  .boxes {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1em;
  }
}
