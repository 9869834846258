
          @import "@/assets/css/vars.scss";
        

.add-new-carrier-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  padding-top: 3em;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-left: 6px solid #3E94FF;
  border-radius: 5px;
  // gap: 2vw;
  // @include flex-gap(2vw, 'column nowrap');
  width: 30em;
  min-height: 40vh;
  // height: 100%;
  h1 {
    margin:0 0 1em 0;
    font-size: 2em;
  }
  button {
    margin: 1em 0;
  }
}
