
          @import "@/assets/css/vars.scss";
        

.agency-details-wrap {
  display: flex;
  flex-direction: column;
  // gap: 1em 0;
  @include flex-gap(3em, 'column nowrap');
  &.top-offset {
    padding-top: $nav-height;
  }
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  h3 {
    margin: 0;
    margin-bottom: 1em;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-wrap {
      width: 60%;
      display: flex;
      align-items: center;
      // gap: 1em 7em;
      @include flex-gap(0em, 'row nowrap');
    }
    .profile-notification-wrap {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
    }
  }
  .carriers-wrap {
    display: flex;
    flex-direction: column;
    padding: max(1em, 1.5vw);
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    // gap: 1em;
    @include flex-gap(1em, 'column nowrap');
    .carriers {
      display: flex;
      // gap: 1.5em;
      @include flex-gap(1.5em, 'row wrap');
      flex-wrap: wrap;
      justify-content: flex-start;
      .carrier {
        width: min(100%, 287px);
        display: flex;
        flex-direction: column;
        // gap: 1em;
        // @include flex-gap(1em, 'column nowrap');
        .del-btn {
          width: 17px;
          height: 17px;
          align-self: flex-end;
          position: absolute;
          margin: 5px;
          &:hover {
            cursor: pointer;
            > {
              img {
                display: initial;
              }
            }
          }
          img {
            height: 100%;
            display: none;
          }
        }
        img {
          height: 171px;
          border-radius: 5px;
          &:hover {
            cursor: pointer;
            + {
              .del-btn {
                > {
                  img {
                    display: initial;
                  }
                }
              }
            }
          }
        }
        .name {
          color: #5f6174;
          font-weight: 600;
          font-size: 0.9em;
          margin: 12px 0;
        }
      }
      .new-carrier {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em;
        width: 287px;
        height: 171px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        cursor: pointer;
        .icon-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          // gap: 1em;
          @include flex-gap(1em, 'column nowrap');
          img {
            width: max-content;
          }
          span {
            color: #5f6174;
            font-weight: 600;
            font-size: 0.9em;
          }
        }
      }
    }
  }
  .forms {
    display: flex;
    flex-wrap: wrap;
    // gap: 1em;
    // @include flex-gap(2em, 'row wrap');
    .agency-info,
    .rep-info {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      // gap: 1em;
      // @include flex-gap(1em, 'column wrap');
      padding: max(1em, 1.5vw);
      background: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      .inputs-wrap {
        display: flex;
        flex-wrap: wrap;
        // gap: 1.5em 1.5em;
        @include flex-gap(1.5em, 'row wrap');
        > * {
          width: 100%;
        }
        .checkbox-wrap-agency {
          display: flex;
          align-items: flex-start;
        }
      }
      .button-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        margin-top: 1em;
        button {
          height: max-content;
        }
      }
    }
    .agency-info {
      margin-right: auto;
    }
    .delete-acc {
      color: $main-blue;
      font-style: italic;
      cursor: pointer;
      margin-left: auto;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: 769px) {
  .agency-details-wrap {
    .forms {
      h3 {
        width: max-content;
      }
      .agency-info {
        flex-basis: calc(35% - 0.5em);
        .inputs-wrap {
          > * {
            width: 100%;
          }
        }
      }
      .rep-info {
        flex-basis: calc(65% - 0.5em);
        .inputs-wrap {
          > * {
            width: calc(47% - 0.75vw);
          }
        }
        .checkbox-wrap-agency {
          > div {
            padding-top: 2.7em;
          }
        }
      }
    }
  }
}
@media (max-width: 896px){
  .carriers {
    justify-content: center!important;
  }
}

