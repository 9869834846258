
          @import "@/assets/css/vars.scss";
        

.quotes-wrap {
  display: flex;
  flex-direction: column;
  // gap: 1em 0;
  height: 100%;
  width: 100%;
  &.top-offset {
    padding-top: $nav-height;
  }
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1em;
    .search-wrap {
      // width: 60%;
      margin-right: 1em;
      display: flex;
      align-items: center;
      // gap: 1em 7em;
      @include flex-gap(1em, 'row nowrap');

    }
    .profile-notification-wrap {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
    }
  }
  .content {
    display: flex;
    .main {
      display: flex;
      flex-wrap: wrap;
      // flex-direction: column;
      // flex-grow: 1;
      width: 100%;
      .quote-card-wrap{
        margin: 0 0 1em 0;
      }
      .safari-fix {
        margin: 0 0 1em 0;
      }
      p {
        color: #172856;
      }
      .no-data {
        min-height: 82vh;
      }
    }
  }
}

@media (min-width: 950px) {
  .quotes-wrap {
    .content {
      .main {
        &.column {
          flex-direction: column;
          flex-wrap: nowrap;
          margin-right: 1em;
        }
        > * {
          //half of the parent having gap property
          // width: calc(50% - 1em);
          flex-basis: calc(50% - .9em);
          &.strech {
            flex-basis: auto;
          }
        }
        .safari-fix {
          margin: 0 1.8em 1em 0;
        }
        .no-data {
          flex-basis: 100%;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .quotes-wrap {
    .header {
      .profile-notification-wrap {
        width: 100%;
        justify-content: space-between;
        @include flex-gap-unset('row nowrap');
        margin-top: 1em;
      }
    }
  }
}
