
          @import "@/assets/css/vars.scss";
        

.base-checkbox {
  display: flex;
  align-items: center;
  // padding-top: 21px;
  // gap: .5em;
  @include flex-gap(.5em, 'row nowrap');
  // width: $input-width;
  .base-checkbox-input {
    cursor: pointer;
    position: fixed;
    opacity: 0;
    height: 25px;
    width: 25px;
    margin: 0;
    // &:checked ~ .custom-checkbox {
    //   background: $main-blue;
    //   .check-icon {
    //     display: block;
    //   }
    // }
  }
  .base-checkbox-label {
    color: #9CA0AF;
    font-weight: 700;
    user-select: none;
    display: flex;
    align-items: center;
    .custom-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 25px;
      min-width: 25px;
      background: #F3F4F4;
      border: 1px solid #E6E6E6;
      border-radius: 6px;
      margin-right: 5px;
      .check-icon {
        display: none;
      }
      &.checked {
        background: $main-blue;
        .check-icon {
          display: block;
        }
      }
    }
    &.checked {
      color: #434961;
    }
  }
}
