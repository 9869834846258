
          @import "@/assets/css/vars.scss";
        

.footer-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // gap: 8em 0;
  // @include flex-gap(4em, 'column nowrap');
  background: $footer-blue;
  background-repeat: no-repeat;
  color: white;
  padding-left: min(12vw, 12em);
  padding-right: min(12vw, 12em);
  padding-top: min(7vw, 7em);
  padding-bottom: min(2vw, 2em);
  .content-wrap {
    display: flex;
    width: 100%;
    > * {
      flex-basis: 100%;
      font-family: 'Poppins', sans-serif;
    }
    h1 {
      font-size: 3.8em;
      margin: 0;
      line-height: 1em;
      color: #FFF;
    }
    .logo-contact {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // gap: 4em 0;
      .logo {
        max-width: 23em;
        display: flex;
        flex-direction: column;
        // gap: 2em;
        img {
          width: 13em;
        }
      }
      p {
        line-height: 2em;
        margin: 1em 0 4em;
        // uncomment for gap
        // margin: 0
      }
      .contact {
        .info {
          display: flex;
          flex-direction: column;
          // gap: .5em;
          margin-top: 2em;
          a{
            text-decoration: none;
            color: inherit;
          }
          // comment for gap
          p {
            margin: 0;
          }
        }
      }
    }
    .get-in-touch {
      line-height: 2em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .form {
        display: flex;
        flex-direction: column;
        // gap: 2em;
        @include flex-gap(2em, 'column nowrap');
        .input-line {
          display: flex;
          // gap: 2em 4em;
          @include flex-gap(4em, 'row nowrap');
          &.one-button {
            > * {
              width: 100%;
            }
          }
          &.two-buttons {
            > * {
              width: calc(50% - 2em);
            }
          }
        }
        button {
          margin-top: 1em;
        }
      }
      p {
        margin: 0;
      }
    }
    .get-in-touch-thx {
      line-height: 2em;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
    }
  }
  .copyright {
    width: 100%;
    text-align: center;
    font-size: .7em;
    padding: 1.5em 0;
    border-top: 1px solid rgba(255, 255, 255, .15);
    // uncomment for gap
    margin-top: 8em;
  }
}
@media (max-width: 1070px) {
  .footer-wrap {
    padding: 4em;
    padding-bottom: 2em;
    // gap: 4em;
  }
}

@media (max-width: 769px) {
  .footer-wrap {
    h1 {
      font-size: 3em !important;
    }
    .content-wrap {
      align-items: center;
      flex-direction: column-reverse;
      // gap: 3em;
      @include flex-gap(3em, 'column-reverse nowrap');
      .logo-contact {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .logo {
          img {
            width: 10em;
          }
        }
      }
      .get-in-touch {
        h1 {
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .footer-wrap {
    padding-left: 2em;
    padding-right: 2em;
    .content-wrap {
      .logo-contact {
        flex-direction: column;
        align-items: center;
        .logo,
        .contact {
          width: 100%;
          max-width: none;
        }
      }
      .get-in-touch {
        width: 100%;
        .form {
          .input-line {
            &.one-button {
              > * {
                width: 100%;
              }
            }
            &.two-buttons {
              flex-wrap: wrap;
              > * {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
