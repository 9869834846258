
          @import "@/assets/css/vars.scss";
        

.wrapper-wrap-xd{
  background: #EFEFF8;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reset-wrap{
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  overflow: hidden;
  max-height: 95vh;
  * {
    font-family: 'Inter';
    // font-weight: 400;
  }
  .form {
    display: flex;
    flex-direction: column;
    // gap: 3em;
    @include flex-gap(4em, 'column nowrap');
    padding: 3em;
    padding-top: 8vw;
    flex-grow: 1;
    width: 40em;
    > p {
      margin: 0;
      color: #172856;
      font-family: 'Inter';
      font-size: clamp(2.5em, 3vw, 3.3em);
      line-height: 1.1;
      margin-bottom: .5em;
    }
    .inputs-wrap {
      display: flex;
      flex-direction: column;
      // gap: 2em;
      @include flex-gap(1em, 'column nowrap');
      .forgot-pw{
        display: flex;
        justify-content: flex-end;
        margin-top: -2em;
        margin-bottom: -3em;
        .forgot-pw-text{
          font-size: .9em;
          cursor: pointer;
          &:hover{
            color: $main-blue;
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .reset-wrap {
    .form {
      width: 55%;
    }
  }
}

@media (max-width: 550px) {
  .reset-wrap {
    .form {
      width: 75%;
    }
  }
}

@media (max-width: 450px) {
  .reset-wrap {
    .form {
      width: 100%;
    }
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .reset-wrap{
    .form {
      > p {
        font-size: 3vw;
      }
    }
  }
}}
