
          @import "@/assets/css/vars.scss";
        

.landing-login {
  display: flex;
  justify-content: space-between;
  background: #EFEFF8;
  border-radius: 10px;
  overflow: hidden;
  max-height: 95vh;
  * {
    font-family: 'Inter';
    // font-weight: 400;
  }
  .form {
    display: flex;
    flex-direction: column;
    // gap: 3em;
    @include flex-gap(6em, 'column nowrap');
    // uncomment for gap
    margin-top: 2em;
    padding: 3em;
    padding-top: 8vw;
    flex-grow: 1;
    width: 40em;
    > p {
      margin: 0;
      color: #172856;
      font-family: 'Inter';
      font-size: clamp(2.5em, 3vw, 3.3em);
      line-height: 1.1;
    }
    > span {
      color: #172856;
      margin: 0;
      margin-top: 2em;
    }
    .inputs-wrap {
      display: flex;
      flex-direction: column;
      // gap: 2em;
      @include flex-gap(2em, 'column nowrap');
      // uncomment for gap
      margin: 2em 0 3em 0;
      .forgot-pw{
        display: flex;
        justify-content: baseline;
        margin-top: -.5em;
        margin-bottom: -3em;
        .forgot-pw-text{
          font-size: .9em;
          cursor: pointer;
          &:hover{
            color: $main-blue;
          }
        }
      }
    }
    .button-wrap {
      margin: 0;
      &#forgor-pw {
        margin-top: 1em;
      }
    }
  }
  .img-wrap {
    // background: url("../../assets/images/landing-agent-registration-image.png") no-repeat center center;
    // flex-basis: 500px;
    // width: max(100%, 700px);
    // width: 600px;
    // background-size: cover;
    img {
      // height: 100%;
      min-height: 102%;
    }
  }
}

@media (max-width: 950px) {
  .landing-login {
    .img-wrap {
        background: url("../../assets/images/user-login-illustration.png") no-repeat center center;
        background-size: cover;
        width: 600px;
      img {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .landing-login {
    .form {
      width: 55%;
    }
  }
}

@media (max-width: 550px) {
  .landing-login {
    .form {
      width: 75%;
    }
  }
}

@media (max-width: 450px) {
  .landing-login {
    .img-wrap {
      display: none;
    }
    .form {
      width: 100%;
    }
  }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .landing-login {
    .form {
      > p {
        font-size: 3vw;
      }
    }
  }
}}
