
          @import "@/assets/css/vars.scss";
        

#home-page {
  position:relative;
  $padding-inline: min(12vw, 12em);
  background-color: #FFF;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  #how-we-diff{
    scroll-margin-top: 120px;
  }
  #how-it-works{
    scroll-margin-top: -40px;
  }
  > * {
    width: min(3840px, 100%);
    padding-left: $padding-inline;
    padding-right: $padding-inline;
  }
  .nav-better-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 5em;
    // @include flex-gap(5em, 'column nowrap');
    padding-top: min(1.5vw, 1.5em);
    padding-bottom: min(5vw, 5em);
    background: url('../assets/images/greatF.png') no-repeat, linear-gradient(260.62deg, #5942E6 -0.28%, #3664D9 102.05%);
    .nav-replacer {
      height: $nav-height;
    }
    .filler{
      height: $nav-height;
      width: 100%;
    }
  }
  .how-it-works {
    display: flex;
    justify-content: center;
    padding-top: min(8vw, 8em);
    padding-bottom: min(5vw, 5em);
    background-repeat: no-repeat;
    background-position: right top;
  }
  .how-we-make-money {
    padding-top: min(3vw, 3em);
    padding-bottom: min(5vw, 5em);
  }
  .how-fairwey-diff {
    padding-top: min(3vw, 3em);
    padding-bottom: min(5vw, 5em);
    position: relative;
    .left-blob {
      left: 0;
      width: 1% important;
      display: inline-block;
      position:absolute
    }
    .graph{
      position: absolute;
      right: 10%;
    }
    .polygon{
      position: absolute;
      right: 5%;
      top: 15%;
    }
  }
  .footer {
    padding: 0;
    background: #4068DA;
  }
}

@media (max-width: 1070px) {
  #home-page {
    > * {
      padding-left: 4em;
      padding-right: 4em;
    }
    .how-fairwey-diff {
      .graph{
        top: 10%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #home-page{
    #how-we-diff{
      scroll-margin-top: 85px;
    }
    #how-it-works{
      scroll-margin-top: -26px;
    }
  }
}

@media (max-width: 600px) {
  #home-page {
    > * {
      padding-left: 2em;
      padding-right: 2em;
    }
    #how-we-diff{
      scroll-margin-top: 90px;
    }
    #how-it-works{
      scroll-margin-top: -27px;
    }
    #footer{
      scroll-margin-top: 170px;
    }
    .how-fairwey-diff {
      .graph{
        top: 10%;
      }
    }
  }
}

@media (max-width: 500px) {
  #home-page {
    .nav-better-wrap{
      // gap: 2em; 
    }
  }
}

@media screen and (max-width: 280px){
  #home-page{
    .how-fairwey-diff{
      .graph{
        top: 12%;
      }
    }
  }
}

// Safari 11+ specific css
// Either rework the scroll function in LandingNavigation.vue and delete this or keep this and pray it works
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  #home-page {
    #how-it-works{
      scroll-snap-margin-top: -35px;
    }
    #how-we-diff{
      scroll-snap-margin-top: 120px;
    }
  }
  @media screen and (max-width: 768px) {
    #home-page{
      #how-we-diff{
        scroll-snap-margin-top: 85px;
      }
      #how-it-works{
        scroll-snap-margin-top: -28px;
      }
    }
  }
  @media (max-width: 500px) {
    #home-page {
      #how-it-works{
        scroll-snap-margin-top: -26px;
      }
    }
  }
}}
