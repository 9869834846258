@use 'sass:math';

$background-color: #F5F6FA;
$main-blue: #3E94FF;
$main-light-blue: #D3E6FD;
$footer-blue: #4068DA;

$button-background-disabled: #F2F2F2;
$button-text-disabled: #C7C7C7;
$button-color-aqua: #2FE2D8;

$input-height: 4em;
$input-width: min(100%, 30em);
$input-error-border-color: #F5A9AD;
$input-error-msg-color: #F25F68;
$input-single-line-color: #3BEFE5;
$input-label-font-size: .9em;

$font-sidebar-color: #767887;

$box-border-color: #E8E8E8;

$nav-height: 5em;

$notification-size: 55px;
//animations
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.list-fade-enter-active {
  transition: opacity 0.3s ease;
}
.list-fade-leave-active {
  transition: opacity 0.3s ease;
  position: absolute;
}
.list-fade-enter-from,
.list-fade-leave-to {
  opacity: 0;
}
.list-fade-move {
  transition: all .4s ease;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all .1s linear;
}
.slide-left-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all .1s linear;
}
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-100px);
}
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

//Base Components shared styles
.error-msg {
  display: flex;
  flex-direction: column;
  gap: .3em;
  position: absolute;
  top: 93%;
  margin: 0;
  color: $input-error-msg-color;
  font-size: .9em;
  font-style: italic;
  font-family: 'Open Sans'; //Open Sans
  font-weight: 600;
  // white-space: nowrap;
}

//notification bubble
.notification-circle {
  $circleSize: 10px;
  background-color: #F95F83;
  min-height: $circleSize;
  min-width: $circleSize;
  max-height: $circleSize;
  max-width: $circleSize;
  border-radius: 50%;
  &.white {
    background-color: #FFF;
  }
}

//seatch input
input.search {
  height: $notification-size;
  width: $input-width;
  min-width: 7em;
  background: #FFFFFF;
  border: 1px solid $box-border-color;
  border-radius: 5px;
  padding: 0 1.5em;
  outline: none;
  font-family: 'Inter';
  &::placeholder {
    font-size: .8em;
  }
}

.has-single-line {
  bottom: -3em;
}

.no-data {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  border: 1px solid $box-border-color;
  border-radius: 5px;
}

@mixin _flex-gap($gap, $row: true) {
  $margin: math.div($gap, 2);
  $transform: -$margin;
  @if $row {
    margin-left: $transform;
    margin-right: $transform;
  } @else {
    margin-top: $transform;
    margin-bottom: $transform;
  }
  > * {
    @if $row {
      margin-left: $margin;
      margin-right: $margin;
    } @else {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
 
@mixin flex-gap($gap, $flex-flow: 'row nowrap') {
  @if $flex-flow== 'row nowrap' or $flex-flow== 'row-reverse nowrap' {
    @include _flex-gap($gap, true);
  } @else if $flex-flow== 'column nowrap' or $flex-flow== 'column-reverse nowrap' {
    @include _flex-gap($gap, false);
  } @else if $flex-flow== 'row wrap' or $flex-flow== 'row-reverse wrap' {
    @include _flex-gap($gap, true);
    @include _flex-gap($gap, false);
  } @else if $flex-flow== 'column wrap' or $flex-flow== 'column-reverse wrap' {
    @include _flex-gap($gap, true);
    @include _flex-gap($gap, false);
  } @else {
    @error "The second paramater $flex-flow is set to be '#{$flex-flow}', which is illegal.";
  }
}

@mixin _flex-gap-unset($row: true) {
  $margin: 0;
  $transform: 0;
  @if $row {
    margin-left: $transform;
    margin-right: $transform;
  } @else {
    margin-top: $transform;
    margin-bottom: $transform;
  }
  > * {
    @if $row {
      margin-left: $margin;
      margin-right: $margin;
    } @else {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
 
// unset flex-gap, used in @media screen width rules
@mixin flex-gap-unset($flex-flow: 'row nowrap') {
  @if $flex-flow== 'row nowrap' or $flex-flow== 'row-reverse nowrap' {
    @include _flex-gap-unset(true);
  } @else if $flex-flow== 'column nowrap' or $flex-flow== 'column-reverse nowrap' {
    @include _flex-gap-unset(false);
  } @else if $flex-flow== 'row wrap' or $flex-flow== 'row-reverse wrap' {
    @include _flex-gap-unset(true);
    @include _flex-gap-unset(false);
  } @else if $flex-flow== 'column wrap' or $flex-flow== 'column-reverse wrap' {
    @include _flex-gap-unset(true);
    @include _flex-gap-unset(false);
  } @else {
    @error "The second paramater $flex-flow is set to be '#{$flex-flow}', which is illegal.";
  }
}