
          @import "@/assets/css/vars.scss";
        

.landing-user-registration {
  display: flex;
  background: #EFEFF8;
  border-radius: 10px;
  overflow: hidden;
  > * {
    flex-basis: 100%;
  }
  .form {
    display: flex;
    flex-direction: column;
    // gap: 3em;
    @include flex-gap(3em, 'column nowrap');
    // uncomment for gap
    margin: 1em 0;
    padding: 3em;
    > p {
      margin: 0;
      color: #172856;
      font-family: 'Inter';
      font-size: clamp(2.5em, 3vw, 3.3em);
      line-height: 1.1;
    }
    .insurances-wrap {
      display: flex;
      flex-direction: column;
      // gap: 2em;
      @include flex-gap(2em, 'column nowrap');
      // uncomment for gap
      margin: 2em 0;
      .insurance-card {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 110px;
        width: 415px;
        img {
          width: 70px;
          height: 70px;
          // object-fit: cover;
        }
        .svg-holder {
          width: 200px;
          height: 100%;
          border: 1px solid #E0E3F0;
          border-right: none;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            path {
              fill: #5446E4;
            }
          }

          &.selected {
            transition: all .2s ease;
            background: #5446E4;
            border: 1px solid #5446E4;
            border-right: none;
            svg {
              path {
                fill: #FFF;
              }
            }
          }
        }
        .paragraph {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          border: 1px solid #E0E3F0;
          width: 100%;
          height: 100%;
          padding: 1em;
          border-left: none;
          font-family: 'Open Sans';
          font-size: .9em;
          &.selected {
            transition: all .2s ease;
            background: #5446E4;
            border: 1px solid #5446E4;
            border-left: none;
            p, h3 {
              color: #FFF;
            }
          }
          h3, p {
            margin: 0;
            color: #6F7486CC;
            font-size: 1em;
          }
          h3 {
            color: #6F7486;
            font-weight: 600;
            font-size: 1.5em;
          }
          p {
            font-style: italic;
          }
        }
      }
      .you-selected {
        p {
          margin: 0;
          color: #5446E4;
          font-size: .8em;
          font-style: italic;
          font-weight: 600;
          font-family: 'Open Sans';
        }
      }
      .terms-wrap{  
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checkbox-wrap {
          position: relative;
          .checkbox {
            height: 2.5em;
          }
          span {
            display: flex;
            flex-direction: column;
            // gap: .3em;
            @include flex-gap(.3em, 'column nowrap');
            position: absolute;
            bottom: -1.5em;
            margin: 0;
            color: $input-error-msg-color;
            font-size: .9em;
            font-style: italic;
            font-family: 'Open Sans';
            font-weight: 600;
            white-space: nowrap;
            padding-left: .5em;
          }
          
        }
        .terms-text{
          display: flex;
          align-items: center;
          a {
            text-decoration: none;
            color: $main-blue;
            font-weight: bold;
          }
        }
      }
    }
    .button-wrap {
      button {
        min-width: 12em;
      }
    }
  }
  .img-wrap {
    // background-image: url("../../assets/images/landing-user-registration-image.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 600px;
    img {
      min-height: 100%;
    }
  }
}

@media (max-width: 1250px) {
  .landing-user-registration {
    .form{
      .insurances-wrap{
        .insurance-card{
          img{
            width: 15em;
          }
        }
      }
    }
    .img-wrap {
      flex-basis: 80%;
    }
  }
}

@media (max-width: 900px) {
  .landing-user-registration {
    .form{
      .insurances-wrap{
        .insurance-card{
          img{
            width: 16em;
          }
        }
      }
    }
    .img-wrap {
      flex-basis: 40%;
    }
  }
}

@media (max-width: 768px) {
  .landing-user-registration {
    .form {
      padding: 2em;
    }
    .img-wrap {
      display: none;
    }
  }
}

@media (max-width: 550px) {
  .landing-user-registration {
    .form {
      padding: 1em;
      p {
        text-align: center;
        font-size: 2em;
        margin-top: 15px;
      }
      .insurances-wrap {
        .insurance-card {
          flex-direction: column;
          height: 90px;
          width: 100%;
          border: 1px solid $button-text-disabled;
          img {
            display: none;
          }
          .paragraph {
            width: 100%;
            padding-top: 0;
            padding-bottom: 0;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .landing-user-registration {
    .form {
      > p {
        font-size: 3vw;
      }
    }
  }
}}
