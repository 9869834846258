
          @import "@/assets/css/vars.scss";
        

.base-select {
  display: flex;
  flex-direction: column;
  position: relative;
  width: $input-width;
  @include flex-gap(.5em, 'column nowrap');
  .base-select-label {
    display: flex;
    align-items: center;
    @include flex-gap(.1em, 'row nowrap');
    font-size: $input-label-font-size;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
  }
  select {
    height: $input-height;
    padding: 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    color: #434961;
    font-family: 'Open Sans';
    //arrow styles
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../assets/icons/select-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 1em);
    background-position-y: calc((#{$input-height} / 2) - 8px);
    background-color: #FFF;
    //arrow
    &.has-error {
      border: 2px solid $input-error-border-color;
    }
    option {
      cursor: pointer;
    }
    &.error {
      border-color: $input-error-border-color;
    }
    &:active, &:focus {
      border: 2px solid #3E94FF;
    }
    &:disabled {
      border: none;
      background: #EFF0F1;
      opacity: 1;
      &:active {
        outline: none;
      }
    }
  }
}
