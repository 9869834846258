
          @import "@/assets/css/vars.scss";
        

#agency-lead-quote {
  .modal-wrap {
    .form-wrap {
      max-width: 65em;
    }
  }
}
