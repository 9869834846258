
          @import "@/assets/css/vars.scss";
        

.burger-container {
  // position: relative;
  // height: 40px;
  // width: 40px;
  &.dark {
    > img {
      filter: brightness(0.5);
    }
  }
  .menu-items {
    position: fixed;
    // height: 90vh;
    top: 68px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
  }
}
