
          @import "@/assets/css/vars.scss";
        

.register-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  .content {
    min-height: calc(100vh - #{$nav-height});
    // margin: 0 auto;
    padding: 1vw 7vw 2vw 7vw;
  }
}

@media (min-width: 769px) {
  .register-wrap {
    .content {
      padding-left: min(12vw, 12em);
      padding-right: min(12vw, 12em);
      padding-top: min(2vw, 2em);
      padding-bottom: min(2vw, 2em);
      a {
        display: flex;
        // gap: 1em;
        @include flex-gap (1em, 'row nowrap');
        color: #84899B;
        text-decoration: none;
        font-weight: 600;
        margin-bottom: 1em;
        width: max-content;
      }
    }
  }
}
