
          @import "@/assets/css/vars.scss";
        

.input-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  // gap: .3em;
  padding-top: 1.5em;
  label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: .9em;
    font-style: italic;
    color: #A3A8B6;
  }
  input {
    border: none;
    height: 3.5em;
    background: #EFEFF8;
    box-shadow: 0px 4px 11px rgba(41, 23, 92, 0.06);
    padding: 0 1.5em;
    color: #172856;
    font-family: "Inter";
    &:active, &:focus {
      border: none;
      outline: none;
    }
  }
  .error {
    display: flex;
    flex-direction: column;
    // gap: .3em;
    position: absolute;
    bottom: -1.5em;
    margin: 0;
    color: $input-error-msg-color;
    font-size: .9em;
    font-style: italic;
    font-family: 'Open Sans';
    font-weight: 600;
    white-space: nowrap;
    padding-left: .5em;
  }
}
