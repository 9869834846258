
          @import "@/assets/css/vars.scss";
        

.sort-wrap {
  display: flex;
  // gap: 1.8em;
  @include flex-gap(1.8em, 'row nowrap');
  padding: 1.2em;
  border-radius: 6px;
  background: #3E94FF33;
  align-items: center;
  color: $main-blue;
  font-weight: 600;
  span {
    font-size: .8em;
    white-space: nowrap;
  }
  .arrows-wrap {
    display: flex;
    flex-direction: column;
    // gap: .2em 0;
    @include flex-gap(.2em, 'column nowrap');
    .bottom-arrow {
      transform: rotate(180deg);
    }
  }
}
