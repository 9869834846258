
          @import "@/assets/css/vars.scss";
        

.verify-wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: min(90%, 40em);
  }
}
