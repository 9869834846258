
          @import "@/assets/css/vars.scss";
        

.quote-card-wrap {
  $border-radius: 10px;
  padding: 15px 12px !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2em;
  // @include flex-gap(4em, 'row wrap');
  width: 100%;
  height: max-content;
  padding: 1em;
  border: 1px solid #F3F3F3;
  border-radius: $border-radius;
  background: #FFF;
  position: relative;
  // overflow: hidden;
  cursor: pointer;
  .background-linear-gradient {
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background: linear-gradient(140.38deg, #3E94FF -15.33%, #7D48EF 133.48%);
    padding: 10px;
    border-radius: $border-radius;
  }
  &.hover {
    * {
      transition: all .3s ease;
      color: white !important;
      opacity: 1;
    }
  }
  .info {
    z-index: 1;
    display: flex;
    flex-direction: column;
    // gap: 1.5em;
    @include flex-gap(1.5em, 'column nowrap');
    .details {
      display: flex;
      flex-direction: column;
      // gap: .2em;
      @include flex-gap(.2em, 'column nowrap');
      .company-name {
        font-weight: 600;
        font-size: 1.2em;
        color: #434961;
        word-break: break-word;
      }
      .breakdown {
        font-family: 'Open Sans';
        font-style: italic;
        font-size: .9em;
        color: $main-blue;
      }
    }
    .quote-num {
      font-weight: 600;
      color: $main-blue;
    }
  }
  .price-wrap {
    z-index: 1;
    display: flex;
    // gap: 1em;
    // margin-top: 2em;
    height: max-content;
    @include flex-gap(1em, 'row nowrap');
    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // gap: 1em;
      @include flex-gap(1em, 'column nowrap');
      border: 1px solid #DEDEDE;
      border-radius: 6px;
      $size: 6em;
      min-width: $size;
      height: $size;
      padding-left: .5em;
      padding-right: .5em;
      span {
        font-size: .7em;
        font-weight: 600;
        color: #9C9EA7;
      }
      .tag {
        font-weight: 600;
        font-size: 1.5em;
      }
      &:hover {
        background: #FFFFFF1A;
      }
      &.hover {
        border: 1px solid #FFFFFF33;
      }
    }
  }
}
.quote_deleted {
  position: absolute;
  z-index: 2;
  inset: 0;
  background-color: #F4F9FF;
  // text-align: center;
  // padding-top: 50px;
  font-size: 1em;
  color: #fff;
  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0 0;
    img {
      height: 15px;
    }
  }
  .body-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 25px;
    .gears {
      margin-right: 20px;
      img {
        height: 60px;
      }
    }
    .deleted-text {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      h3 {
        color: #434961;
        margin: 0;
      }
      span {
        margin-top: 10px;
        font-style: italic;
        color: #3E94FF;
      }
    }
  }
}

@media (max-width: 500px) {
  .quote-card-wrap {
    justify-content: center;
    // .price-wrap {
    //   // margin-top: 2em;
    // }
  }
}

// Safari
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  // @media (max-width: 500px) {
    .quote-card-wrap {
      .price-wrap {
        margin-top: 2em;
      }
    }
  // }
}}
