
          @import "@/assets/css/vars.scss";
        

.base-radio-group {
  position: relative;
  display: flex;
  flex-direction: column;
  width: $input-width;
  outline: none;
  &.max-width {
    width: 100%;
  }
  .base-radio-label {
    display: flex;
    align-items: center;
    font-size: $input-label-font-size;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
  }
  .radio-wrap {
    min-height: $input-height;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-bottom: .5em;
    border-radius: .5em;
    padding: .2em .5em;
    // font-size: .8em;
    > * {
      margin: .2em 0 !important;
    }
    &.vertical {
      flex-direction: column;    
    }
    &.has-error {
      border: 2px solid $input-error-border-color;
    }
    > * {
      margin: .3em;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
