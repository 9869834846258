
          @import "@/assets/css/vars.scss";
        

.notification-wrap {
  position: relative;
  user-select: none;
  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $notification-size;
    min-width: $notification-size;
    max-height: $notification-size;
    max-width: $notification-size;
    background-color: #FFF;
    border: 1px solid $box-border-color;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    .notification-circle {
      position: absolute;
      top: 12px;
      right: 15px;
    }
  }
  .notifications {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(#{$notification-size} + 5px);
    right: 0;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    overflow: hidden;
    z-index: 3;
    .notification {
      display: flex;
      flex-direction: column;
      padding: 1em;
      width: 20em;
      &.new {
        cursor: pointer;
        background: #F0FAF3;
      }
      .heading {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .name {
          color: #434961;
          font-weight: 600;
        }
        .time {
          font-style: italic;
          color: #999BA3
        }
      }
      .text {
        color: #6C7490;
        &.has-border {
          margin: 0;
          padding: 1em 0 2em 0;
          border-bottom: 1px solid #EDEDED;
        }
      }
    }
    .see-all {
      display: flex;
      justify-content: center;
      padding: .8em;
      background: #FAFAFA;
      span {
        color: #3E94FF;
        font-weight: 600;
        font-style: italic;
        text-decoration: underline;
        font-size: .7em;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 280px){
  .notification-wrap{
    .notifications{
      width: 250px;
      .notification{
        width: 240px;
      }
    }
  }
}
