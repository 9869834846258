
          @import "@/assets/css/vars.scss";
        

#agent-user-info-modal {
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-left: 6px solid #3E94FF;
  border-radius: 5px;
  // gap: 2em;
  .header {
    h1 {
      font-size: 2em;
    }
  }
  .input-wrap {
    display: flex;
    flex-direction: column;
    margin: 2em 0;
    // gap: 2em;
    .base-input {
      margin-bottom: 2em;
    }
  }
}
