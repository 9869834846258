
          @import "@/assets/css/vars.scss";
        

.user-profile-wrap {
  display: flex;
  min-height: 100vh;
  .burger {
    position: absolute;
    top: 0;
    left: 0;
  }
  .content {
    padding: clamp(1em, 3vw, 3em);
    width: 100%;
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .user-profile-wrap {
    .content {
      padding: 3vw;
    }
  }
}}
