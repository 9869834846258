
          @import "@/assets/css/vars.scss";
        

.notification {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 1em;
  &.new {
    background: #F0FAF3;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .name {
      color: #434961;
      font-weight: 600;
    }
    .rightHeader {
      display: flex;
      align-items: center;
      .time {
        font-style: italic;
        color: #999BA3;
        margin-right: 10px;
      }
      .close {
        height: 15px;
        &:hover{
          cursor: pointer;
        }
        img {
          height: 15px;
          filter: contrast(0%);
        }
      }
    }

  }
  .text {
    color: #6C7490;
    transition: all .3s ease;
    &.ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      transition: all .3s ease;
    }
  }
  .read-more {
    display: flex;
    @include flex-gap(.5em, 'row nowrap');
    cursor: pointer;
    width: max-content;
    &.less {
      img {
        transform: rotate(180deg);
      }
    }
    span {
      margin-top: -2px;
      font-size: .8em;
      color: $main-blue;
    }
    img {
      width: 10px;
      height: 10px;
    }
    &.has-border {
      margin: 0;
      padding: 1em 0 2em 0;
      border-bottom: 1px solid #EDEDED;
    }
  }
}
