
          @import "@/assets/css/vars.scss";
        

.step-wrap {
  width: 100%;
  h2.title {
    display: flex;
    margin: 0;
    margin-bottom: .7em;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    // gap: 2em 4em;
    @include flex-gap(2em, 'column wrap');
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .step-wrap {
    .form {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      > * {
        width: calc(33% - 2.54em) !important;
      }
    }
  }
}
