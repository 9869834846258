
          @import "@/assets/css/vars.scss";
        

.toast-wrapper {
  position: fixed;
  width: 18em;
  bottom: 6em;
  right: 6em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // gap: .5em;
  z-index: 9999;
}

.list-enter-active {
  transition: all .3s ease-out;
}
.list-leave-active {
  position: absolute;
  transition: all .3s ease-out;
}
.list-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.list-move {
  transition: all .3s;
}
