
          @import "@/assets/css/vars.scss";
        

.base-type-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  @include flex-gap(.5em, 'column nowrap');
  .base-label-el {
    display: flex;
    align-items: center;
    // @include flex-gap(0em, 'row nowrap');
    font-size: $input-label-font-size;
    font-weight: 600;
    color: #656B7E;
  }
  .input {
    background-color: #FFF;
    display: flex;
    align-items: center;
    @include flex-gap(0, 'row nowrap');
    height: $input-height;
    padding: 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    transition: all .2s ease;
    outline: none;
    &.error {
      border-color: $input-error-border-color;
      // &:active, &:focus {
      //   border: 2px solid $input-error-border-color;
      // }
    }
    &.is-open {
      border-radius: .5em .5em  0 0;
    }
    img {
      max-width: 4.5em;
      max-height: 2.5em;
    }
    &.disabled {
      border: none;
      background: #EFF0F1;
    }
  }
  .options {
    padding: .5em 0;
    position: absolute;
    width: 100%;
    background: #FFF;
    overflow: auto;
    top: 100%;
    z-index: 2;
    border-radius: 0 0 11px 11px;
    border: 1px solid $box-border-color;
    border-top: none;
    max-height: 10em;
    .option {
      // position: relative;
      display: flex;
      align-items: center;
      @include flex-gap(1em, 'row nowrap');
      padding: 0 .5em;
      width: 100%;
      cursor: pointer;
      .option-hover {
        position: absolute;
        left: 50%;
        color: #FFF;
        background-color: $main-blue;
        z-index: 10;
        padding: .5em;
        border-radius: 6px;
        .triangle {
          position: absolute;
          $size: 10px;
          width: $size;
          height: $size;
          left: -5px;
          top: calc(50% - 5px);
          transform: rotate(45deg);
          background-color: $main-blue;
        }
      }
      img {
        max-width: 4.5em;
        max-height: 2.5em;
      }
      &:hover {
        background: $main-blue;
        color: #FFF;
        transition: all .2s ease;
      }
      &.selected {
        background: #CECEE1;
      }
      &.disabled {
        background: #EFF0F1;
        &:hover {
          color: black;
        }
      }
    }
  }
  .x {
    user-select: none;
    position: absolute;
    top: .3em;
    right: 1em;
    cursor: pointer;
    //arrow styles
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../assets/icons/select-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(50%);
    background-position-y: -.2em;
    background-color: #FFF;
    //arrow
    height: 15px;
    width: 15px;
    transition: all .2s;
    &.has-label {
      top: 3.5em;
    }
    &.is-open {
      transform: rotate(180deg);
      top: 2.9em;
    }
  }
}
