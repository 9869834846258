
          @import "@/assets/css/vars.scss";
        

.application-wrap {
  .notice-wrap {
    p {
      font-weight: 600;
      font-size: 1.2em;
    }
  }
}
