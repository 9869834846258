
          @import "@/assets/css/vars.scss";
        

.dashboard-wrap {
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  display: flex;
  flex-direction: column;
  // gap: 1em 0;
  &.top-offset {
    padding-top: $nav-height;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    // gap: 3vw;
    // @include flex-gap(3vw, 'row nowrap');
    .search-wrap {
      display: flex;
      // gap: 1em;
      // @include flex-gap(1em, 'row nowrap');
      align-items: center;
      flex-grow: 1;
    }
    .profile-notification-wrap {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
    }
  }
  .main {
    display: flex;
    flex-wrap: wrap;
    // gap: 1em;
    @include flex-gap(1em, 'row wrap');
    overflow: hidden;
    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      // gap: 5em 0;
      background-color: #FFF;
      padding: clamp(1em, 3vw, 2em);
      border-radius: 5px;
      max-height: 80vh;
      .form {
        overflow: auto;
        display: flex;
        flex-direction: column;
        @include flex-gap(.5em, 'column nowrap');
        padding-bottom: 2px;
        padding-right: 5px;
        min-height: 22em;
        .base-input,
        .base-type-dropdown,
        .base-radio-group,
        .base-checkbox,
        .base-select,
        .calendar-wrap {
          margin-bottom: 1.5em;
        }
        .delete-acc {
          color: $main-blue;
          font-style: italic;
          cursor: pointer;
          margin-bottom: 5px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .button-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2em;
        margin-bottom: 1em;
        // gap: 0 1em;
        > button {
          width: 12em;
        }
      }
    }
    .stats {
      display: flex;
      flex-direction: column;
      // gap: 1em;
      // @include flex-gap(1em, 'column nowrap');
      $block-padding: clamp(.8em, 3vw, 1.2em);
      width: min(100%, 2550px);
      h3, h4, p {
        margin: 0;
      }
      .graph {
        text-align: center;
        padding: 2vw 3vw;
        border-radius: 5px;
        background: #FFF;
        margin-bottom: 1em;
        // height: 100%;
        flex-grow: 1;
      }
      .status-pending {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        .status {
          display: flex;
          flex-direction: column;
          // gap: 2em 0;
          // @include flex-gap(2em, 'column nowrap');
          background-color: #FFF;
          padding: $block-padding;
          border-radius: 5px;
          flex-grow: 1;
          .header {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            flex-wrap: wrap;
            margin-bottom: 1.5em;
            .edit {
              color: #448CFD;
              font-weight: 600;
              cursor: pointer;
            }
          }
          .light-blue {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2em 0;
            background-color: rgba(86, 119, 249, 0.2);
            color: #5775F8;
            border-radius: 10px;
            outline: none;
            border: none;
            font-weight: 600;
            padding: 1em 1.5em;
            width: max-content;
          }
          .paragraph {
            display: flex;
            flex-direction: column;
            // gap: .5em 0;
            // @include flex-gap(0.5em, 'column nowrap');
            margin-bottom: 1.5em;
            .transparent {
              opacity: .5;
              font-size: .95em;
            }
          }
        }
        .pending {
          display: flex;
          flex-direction: column;
          background: linear-gradient(146.33deg, #3E94FF -3.38%, #7D48EF 108.41%);
          border-radius: 5px;
          color: #FFF;
          padding: $block-padding;
          width: 100%;//MOBILE
          // gap: 5.5em 0;
          .header {
            display: flex;
            margin-bottom: 6.5em; // it already had 1em margin
            // gap: 0 4.5em;
            font-weight: 600;
            img {
              max-width: 17px;
              margin-left: 4.5em;
            }
            p {
              font-size: 1.1em;
              cursor: pointer;
            }
          }
          .quotes {
            .number {
              font-family: 'Open Sans';
              font-weight: 600;
              font-size: 3.5em;
            }
            span {
              font-size: .85em
            }
          }
          .quotes-chosen{
            font-family: inherit;
            font-weight: 600;
            text-align: center;
            font-size: 1.6em;
          }
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .dashboard-wrap {
    .main {
      flex-wrap: nowrap;
      .info {
        width: max-content;
      }
      .stats {
        .status-pending {
          flex-wrap: initial;
          .pending {
            width: 20em;
          }
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .dashboard-wrap {
    .main {
      .stats {
        .status-pending {
          flex-wrap: wrap;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .dashboard-wrap {
    .main {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 280px){
  .dashboard-wrap{
    .header{
      .search-wrap{
        h1{
          max-width: 70%;
          white-space: normal;
        }
      }
    }
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .dashboard-wrap {
    .main {
      .info {
        padding: 3vw;
      }
      .stats {
        .status-pending {
          .status {
            padding: 3vw;
          }
          .pending {
            padding: 3vw;
          }
        }
      }
    }
  }
}}
