
          @import "@/assets/css/vars.scss";
        

#not-found-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 1em;
  margin: 0 1em;
  p {
    color: #434961;
    font-size: 1.4em;
    font-weight: 600;
    text-align: center;
    max-width: 25em;
    span {
      font-size: 1em;
    }
  }
}
