
          @import "@/assets/css/vars.scss";
        

#calendar {
  background: #FDFDFE;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 10px 10px;
  padding: 1em;
  border-top: 4px solid $main-blue;
  color: #414141;
  width: 22em;
  max-width: 115%;
}
#month {
  display: flex;
  justify-content: center;
  align-items: center;
  .month-content {
    max-width: 100%;
  }
}
.prev .next {
  justify-content: center;
  align-items: center;
}

.selector {
  display: flex;
  justify-content: center;
  // gap: 0 1em;
  @include flex-gap(1em, 'row nowrap');
  
}
.picker {
  width: 120px;
  max-width: 100%;
  margin-bottom: 1em;
  outline: none;
  background: #FFF;
  border: 1px solid #DBDBDB;
  box-sizing: border-box;
  border-radius: 4px;
  padding: .3em;
  font-weight: 600;
  color: #3E3E3E;
  cursor: pointer;
}
#month {
  display: flex;
}
.prev .next {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.days-preview {
  display: flex;
  width: 100%;
  flex-direction: column;
  // gap: .4em 0;
}
.week-days {
  display: flex;
  width: 100%;
  font-size: 11px;
}
.current-month {
  display: flex;
  // gap: 1.6em 0;
  font-size: 11px;
  font-weight: 600;
}
.day-names {
  width: calc(20em / 7);
  text-align: center;
}
.days {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 7);
  height: calc(20em / 7);
  cursor: pointer;
}
.day-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 70%;
  &:hover {
    background: $button-background-disabled;
  }
}
.day-wrap.selected, .day-wrap.gray.selected {
  background: $main-blue;
  color: white;
}

.day-wrap.gray {
  color: #747474;
  &:hover {
    background: $button-background-disabled;
  }
}

.day-of-month {
  display: flex;
  flex-wrap: wrap;
  /* padding : 5px; */
  width: 100%;
}
.current-month {
  display: flex;
  flex-wrap: wrap;
}
