
          @import "@/assets/css/vars.scss";
        

  * {
    user-select: none;
    box-sizing: border-box;
    outline: none;
  }
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: #F9F9F9;
    width: 100%;
    padding: 1%;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: flex-end;
    align-items: center;

  }
  .go-to {
    display: flex;
    justify-content: center;
    width: 30%;
  }
  .page, .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
  .page {
    width: 7%;
    margin: 0 1%;
    color: #434961;
    border-radius: 6px;
    padding: .2em;
  }
  .selected {
    background-color: #7D48EF;
    font-weight: 600;
    // font-size: 1.1em;
    color: white;
    transition: all .3s ease;
    border-radius: 6px;
  }
  .button {
    font-size: 0.8em;
    width: 15%;
    img {
      max-width: 12px;
      max-height: 12px;
    }
    &.prev {
      img {
        transform: rotate(90deg);
      }
    }
    &.next {
      img {
        transform: rotate(270deg);
      }
    }
  }

  @media (max-width: 950px) {
    .buttons {
      width: 70%;
    }
  }

  @media (max-width: 700px) {
    .pagination {
      flex-direction: column;
    }
    .buttons {
      width: 100%;
      justify-content: center;
    }
    .go-to {
      justify-content: center;
      width: 100%;
      margin-bottom: 2%;
    }
    .go-to input {
      width: 50%;
    }
  }
