
          @import "@/assets/css/vars.scss";
        

.register-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - #{$nav-height});
    padding: 1vw 7vw 2vw 7vw;
    .link-wrap {
      width: min(100%, 80em);
      margin: 0 auto;
      a {
        width: max-content;
        display: flex;
        // gap: 1em;
        color: #84899B;
        text-decoration: none;
        font-weight: 600;
        margin-bottom: 4em;
        img {
          margin-right: 1em;
        }
      }
    }
    .form-wrap {
      margin: 0 auto;
      width: min(100%, 60em);
      display: flex;
      flex-direction: column-reverse;
      // flex-wrap: wrap;
      // gap: 1em;
      background: #FFF;
      padding: 1.5em;
      border: 1px solid #DADADA;
      border-left: 6px solid #3E94FF;
      .form {
        display: flex;
        flex-direction: column;
        // gap: 3em;
        @include flex-gap(3em, 'column nowrap');
        // width: 50%;
        .header {
          display: flex;
          flex-direction: column;
          // gap: 2em;
          p {
            margin-top: 2em;
            font-size: 1.5em;
            color: $main-blue;
          }
        }
        .fields {
          display: flex;
          flex-direction: column;
          // gap: 2em;
          flex-grow: 1;
          #card-element {
            margin-bottom: 2em;
          }
          .base-checkbox {
            margin: 1em 0 0 0;
          }
          .boxes {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-bottom: 1.5em;
            .error-msg{
              position: relative;
              top: 0;
              margin: 0;
              gap: 0;
            }
            .terms-text {
              color: $main-blue;
            }
          }
        }
        p {
          color: #313450;
          font-weight: 600;
        }
        .saved-card {
          margin: 0;
          margin-top: auto;
          color: $main-blue;
          cursor: pointer;
          text-decoration: underline;
          font-weight: 400;
          width: max-content;
        }
        h1 {
          font-size: 2.5em;
          margin: 0;
        }
        .message {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // gap: 2em;
          padding: 1em;
          background: #EAEAF8;
          border-left: 5px solid #4092FE;
          p {
            font-size: .8em;
            color: #172856;
            margin: 0;
            font-weight: 600;
          }
          .icon {
            min-width: 35px;
            min-height: 35px;
            margin-left: 2em;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

      }
      .illustration {
        margin-right: 1em;
        img {
          width: max-content;
        }
      }
    }
    .button-line {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

@media (min-width: 769px) {
  .register-wrap {
    .content {
      .form-wrap {
        margin: 0 auto;
        width: min(100%, 80em);
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;
        // gap: 1em;
        // @include flex-gap(1em, 'row nowrap');
        background: #FFFFFF;
        padding: 1.5em;
        border: 1px solid #DADADA;
        border-left: 6px solid #3E94FF;
        .form {
          display: flex;
          flex-direction: column;
          // gap: 3em;
          @include flex-gap(3em, 'column nowrap');
          width: 40%;
        }
        .illustration {
          img {
            width: max-content;
          }
        }
      }
      .button-line {
        display: flex;
        justify-content: center;
      }
    }
  }
}
