
          @import "@/assets/css/vars.scss";
        

.quote-form {
  display: flex;
  flex-direction: column;
  // gap: 1.5em;
  @include flex-gap(1.5em, 'column nowrap');
  // margin: 0 auto;
  padding: min(1.5em, 2vw);
  width: max-content;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  position: relative;
  h3 {
    margin: .5em 0;
  }
  .close-btn {
    position: absolute;
    right: 1em;
    // uncomment for gap
    top: .2em;
    cursor: pointer;
  }
  .price-row {
    display: flex;
    align-items: flex-end;
    // gap: 1em;
    @include flex-gap(1em, 'row nowrap');
    padding-bottom: 2em;
    border-bottom: 2px solid #DEDEE9B3;
    &.is-last {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .button-line {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 450px) {
  .price-row {
    flex-wrap: wrap;
  }
}
@media (max-width: 769px) {
  .quote-form {
    padding: 3em;
  }
}
