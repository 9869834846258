
          @import "@/assets/css/vars.scss";
        

.confirm-wrap {
  padding: 3vw 4vw;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    @include flex-gap(3vw, 'column nowrap');
    color: #546882;
    .button-line {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
    }
  }
}
