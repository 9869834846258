
          @import "@/assets/css/vars.scss";
        

.profile-sidebar-wrap {
  // position: sticky;
  // top: 0;
  background-color: #FFF;
  // min-height: 100vh;
  .profile-sidebar {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    width: clamp(12em, 13vw, 15em);
    padding: 1.8em 1em;
    // gap: 4em 0;
    @include flex-gap(4em, 'column nowrap');
    .logo {
      img {
        width: 8em;
      }
    }
    .menu {
      display: flex;
      flex-direction: column;
      // gap: .6em 0;
      @include flex-gap(.6em, 'column nowrap');
      .menu-item {
        display: flex;
        // gap: .8em;
        @include flex-gap(.8em, 'row nowrap');        
        font-weight: 600;
        color: $font-sidebar-color;
        padding: 1em;
        text-decoration: none;
        border-radius: 8px;
        transition: all .2s;
        span {
          font-size: .9em;
        }
        &.router-link-active {
          background-color: $main-blue;
          box-shadow: 0px 5px 14px rgba(62, 148, 255, 0.4);
          color: #FFF;
          img {
            filter: brightness(3);
          }
        }
      }
      .disabled{
        pointer-events: none;
      }
    }
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .profile-sidebar-wrap {
    .profile-sidebar {
      min-width: 12em;
      width: 13vw;
      min-width: 15em;
    }
  }
}}
