
          @import "@/assets/css/vars.scss";
        

.nav-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $nav-height;
  background-image: linear-gradient(to right, #3E94FF, #886CF5);
  padding: 1em;
  .logo {
    max-height: 80%;
  }
  &.is-mobile {
    height: $nav-height;
    justify-content: space-between;;
  }
}
