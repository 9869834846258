
          @import "@/assets/css/vars.scss";
        

@media (max-width: 650px) {
  #agent-quote-summary {
    .form {
      .inputs {
        label {
          width: 100%;
        }
      }
    }

    .questionnaire-wrap {
      overflow: auto;
    }

    .wizard-wrap {
      overflow: auto;
      margin-bottom: 0;
    }
  }
}

#agent-quote-summary {
  .questionnaire-wrap {
    min-height: 420px;
    overflow-y: auto;
  }
}
