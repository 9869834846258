
          @import "@/assets/css/vars.scss";
        

.landing-agent-registration {
  display: flex;
  justify-content: space-between;
  background: #EFEFF8;
  border-radius: 10px;
  overflow: hidden;
  max-height: 95vh;
  * {
    font-family: 'Inter';
    // font-weight: 400;
  }
  .confirm-email {
    max-height: 100%;
  }
  .form {
    display: flex;
    flex-direction: column;
    // gap: 2vw;
    // @include flex-gap(1vw, 'column nowrap');
    padding: clamp(1em, 1.5vw, 3em);
    flex-grow: 1;
    max-width: 40em;
    overflow: auto; 
    scroll-behavior: smooth;
    > p {
      margin: 0;
      color: #172856;
      font-family: 'Inter';
      font-size: clamp(1.8em, 3vw, 3em);
      line-height: 1.1;
      font-weight: 600;
    }
    .inputs-wrap {
      display: flex;
      flex-direction: column;
      // padding: 1em 0 0 0;
      @include flex-gap(1.5em, 'column nowrap');
      .terms-wrap{  
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checkbox-wrap {
          position: relative;
          .checkbox {
            height: 2.5em;
          }
          span {
            display: flex;
            flex-direction: column;
            // gap: .3em;
            @include flex-gap(.3em, 'column nowrap');
            position: absolute;
            bottom: -1.5em;
            margin: 0;
            color: $input-error-msg-color;
            font-size: .9em;
            font-style: italic;
            font-family: 'Open Sans'; //Open Sans
            font-weight: 600;
            white-space: nowrap;
            padding-left: .5em;
          }
          
        }
        .terms-text{
          display: flex;
          align-items: center;
          a {
            text-decoration: none;
            color: $main-blue;
            font-weight: bold;
          }
        }
      }
    }
    .message {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // gap: 2em;
      @include flex-gap(2em, 'column nowrap');
      padding: 1em;
      background: #EAEAF8;
      border-left: 5px solid #4092FE;
      p {
        font-size: .8em;
        color: #172856;
        margin: 0;
      }
      .icon {
        min-width: 35px;
        min-height: 35px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .button-wrap {
      margin-top: 2vw;
    }
  }
  .img-wrap {
    // background: url("../../assets/images/landing-agent-registration-image.png") no-repeat center center;
    // flex-basis: 500px;
    // width: max(100%, 700px);
    // width: 600px;
    // background-size: cover;
    img {
      // height: 100%;
      min-height: 102%;
    }
  }
}

@media (max-width: 950px) {
  .landing-agent-registration {
    .img-wrap {
        background: url("../../assets/images/agent-registration-illustration.png") no-repeat center center;
        background-size: cover;
        width: 600px;
      img {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .landing-agent-registration {
    .form {
      width: 55%;
    }
  }
}

@media (max-width: 550px) {
  .landing-agent-registration {
    .form {
      width: 75%;
      > p {
        text-align: center;
      }
    }
  }
}

@media (max-width: 450px) {
  .landing-agent-registration {
    max-height: 85vh;
    .img-wrap {
      display: none;
    }
    .form {
      width: 100%;
    }
  }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .landing-agent-registration {
    .form {
      padding: 1.5vw;
      > p {
        font-size: 3vw;
      }
    }
  }
}}
