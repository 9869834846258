
          @import "@/assets/css/vars.scss";
        

.notifications-wrap {
  height: 100%;
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  display: flex;
  flex-direction: column;
  // gap: 1em 0;
  @include flex-gap(1em, 'column nowrap');
  &.top-offset {
    padding-top: $nav-height;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 3vw;
    @include flex-gap(3vw, 'row nowrap');
    .search-wrap {
      display: flex;
      gap: 1em;
      align-items: center;
      flex-grow: 1;
    }
    .profile-notification-wrap {
      display: flex;
      gap: 1em;
      .notifications-wrap {
        margin-right: 1em;
      }
    }
  }
  .notifications {
    display: flex;
    flex-direction: column;
    // border: 1px solid #E9E9E9;
    border-radius: 10px;
    overflow: hidden;
    // gap: .5em;
    @include flex-gap(0.5em, 'column nowrap');
  }
  .button-line {
    display: flex;
    justify-content: space-between;
    margin-left: .4em;
  }
}

@media (min-width: 769px) {}
