
          @import "@/assets/css/vars.scss";
        

$boxSize: $notification-size;
.profile-box-wrap {
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 6.5em;
  height: $boxSize;
  background-color: #fff;
  border: 1px solid $box-border-color;
  border-radius: 5px;
  position: relative;
  user-select: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 155.8%;
  color: #767887;
  .icon-wrap {
    display: flex;
    .arrow {
      transform: scale(0.7);
      cursor: pointer;
    }
  }
  button {
    width: 100%;
  }
}
