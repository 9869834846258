
          @import "@/assets/css/vars.scss";
        

.step-wrap {
  h2.title {
    display: flex;
    // gap: 0 10px;
     @include flex-gap(10px, 'row nowrap');
    margin: 0;
    margin-bottom: max(2em, 1.8vw);
    span {
      font-size: 1.4em;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    // gap: 1.7em 4em;
    @include flex-gap(1.7em, 'row wrap');
    .left, .right {
      width: calc(50% - 2em);
      display: flex;
      flex-direction: column;
      gap: 1.7em 4em;
      &.single {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        > * {
          width: calc(50% - 2em);
        }
      }
    }
  }
}

@media (max-width: 1367px) {
  .step-wrap {
    .form {
      > * {
        width: 100% !important;
      }
      .left {
        &.single {
          flex-wrap: nowrap;
          flex-direction: column;
          > * {
            width: $input-width;
          }
        }
      }
    }
  }
}

@media (max-width: 689px) {
  .step-wrap {
    .form {
      > * {
        width: calc(100% - 2em) !important;
      }
    }
  }
}
