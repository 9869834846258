
          @import "@/assets/css/vars.scss";
        

* {
  font-family: 'Open Sans';
}
.calendar-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: $input-width;
  @include flex-gap(.5em, 'column nowrap');
  .base-label-el {
    display: flex;
    align-items: center;
    @include flex-gap(.1em, 'row nowrap');
    font-size: .9em;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
  }
  .base-input-el {
    height: $input-height;
    padding: 0 3em 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    color: #434961;
    &::placeholder {
      font-weight: 400;
      color: #9CA0AF;
    }
    &.error {
      border-color: $input-error-border-color;
      &:active, &:focus {
        border: 2px solid $input-error-border-color;
      }
    }
    &:active, &:focus {
      border: 2px solid $main-blue;
    }
    &:disabled {
      border: none;
      background: #EFF0F1;
    }
  }
  .calendar-icon {
    position: absolute;
    bottom: calc(#{$input-height} / 2 - 8px);
    right: 20px;
    cursor: pointer;
  }
  .calendar-wrap {
    position: absolute;
    top: calc(#{$input-height} - .8em);
    right: 0;
    z-index: 10;
    &.has-label {
      top: calc(#{$input-height} + 1.1em);
    }
  }
}
