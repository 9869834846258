
          @import "@/assets/css/vars.scss";
        

.base-radio {
  display: flex;
  align-items: center;
  position: relative;
  @include flex-gap(.8em, 'row nowrap');
  // width: $input-width;
  transition: all 1s ease;
  .base-radio-input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    height: 25px;
    width: 25px;
    &:checked ~ .custom-checkbox {
      transition: all .1s ease;
      border: 8px solid $main-blue;
      background: #FFF;
    }
  }
  .base-radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #9CA0AF;
    font-weight: 700;
    padding-bottom: 2px;
    &.checked {
      color: #434961;
    }
  }
  .custom-checkbox {
    min-height: 25px;
    min-width: 25px;
    background: #F3F4F4;
    border: 1px solid #E6E6E6;
    border-radius: 50%;
  }
}
