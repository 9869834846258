
          @import "@/assets/css/vars.scss";
        

.register-application-wrap {
  display: flex;
  flex-direction: column;
  min-height: 65vh;
  // gap: 2.5vw;
  @include flex-gap(2.5em, 'column wrap');
  margin-bottom: 2em;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      width: 100%;
      text-align: center;
      margin: 0;
      font-size: 2em;
    }
    .step {
      .active-step {
        color: $main-blue;
      }
      span {
        color: #a7adc2;
        font-weight: 600;
        font-size: 1.6em;
      }
    }
  }
  .content-wrap {
    display: flex;
    flex-direction: column;
    .wizard-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: .8em;
    }
    .content {
      max-width: 1180px;
      display: flex;
      flex-direction: column;
      // gap: 2em;
      @include flex-gap(.5em, 'column nowrap');
      background: #FFFFFF;
      padding: 1.5em;
      border: 1px solid #DADADA;
      border-left: 6px solid #3E94FF;
      // overflow: hidden;
    }
  }
  .button-wrap {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    // gap: 1em;
    @include flex-gap(0em, 'row-reverse nowrap');
    margin-top: 2em !important;
  }
}

@media (min-width: 769px) {
  .register-application-wrap {
    .header {
      .title {
        width: auto;
        text-align: left;
      }
      justify-content: space-between;
    }
    .content-wrap {
      flex-direction: row;
      .content {
        width: 100%;
        padding: 4em;
      }
    }
    .wizard-wrapper {
      width: min(100%, 35em);
      height: 100%;
    }
    .button-wrap {
      justify-content: flex-start;
    }
  }
}
