
          @import "@/assets/css/vars.scss";
        

.questionnaire-wrap {
  display: flex;
  flex-direction: column;
  // gap: .5em;
  @include flex-gap(.5em, 'column nowrap');
  padding: 1em 2vw;
  background: #FFF;
  border: 2px solid #D7DEE8;
  border-radius: 10px;
  max-width: 3840px;
  transition: all .2s ease-out;
  position: relative;
  &.overflow {
    overflow: hidden;
  }
  .locked-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    border-radius: 10px;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 1em;
      @include flex-gap(1em, 'column nowrap');
      max-width: 26em;
      max-height: 100%;
      padding: 0 1em;
      img {
        max-width: 12em;
      }
      .title {
        display: flex;
        align-items: center;
        // gap: min(0.5em, 1vw);
        @include flex-gap(.5em, 'row nowrap');
        span {
          font-weight: 600;
          font-size: 1.1em;
          display: flex;
          align-items: center;
          &.home {
            color: $button-color-aqua;
          }
          &.life {
            color: #654BE4;
          }
          &.auto {
            color: $main-blue;
          }
          span.questionnaire {
            color: #313450;
            margin-left: .5em;
          }
        }
      }
      .info {
        font-size: .9em;
        color: #888FA6;
        text-align: center;
        line-height: 1.8em;
      }
      // button {
      //   position: absolute;
      //   top: 1em;
      //   right: 1em;
      // }
    }
  }
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // gap: 1em 2em;
    @include flex-gap(2em, 'row wrap');
    font-weight: 600;
    .title {
      display: flex;
      align-items: center;
      // gap: min(0.5em, 1vw);
      @include flex-gap(.5em, 'row nowrap');
      margin-left: .7em;
      span {
        &.home {
          color: $button-color-aqua;
        }
        &.life {
          color: #654BE4;
        }
        &.auto {
          color: $main-blue;
        }
      }
      .sec-name {
        font-size: 1.25em;
        color: #434961;
      }
    }
    .wizard-wrapper {
      display: flex;
      justify-content: flex-end;
      min-width: 20em;
      &.one-element {
        min-width: auto;
      }
    }
  }
  .section-text {
    font-weight: 600;
    color: #434961;
    margin: .5em 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    // gap: 1em;
    // @include flex-gap(1em, 'column nowrap');
    overflow: visible;
    .buttons-line {
      display: flex;
      margin: .5em 0;
      flex-direction: row-reverse;
      // gap: 2em;
      @include flex-gap(1em, 'row-reverse nowrap');
    }
  }
}
.active{
  // transition: all .2s ease-out;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.06);
  // transform: scale(1.02);
  border-color: $main-blue;
}

@media (min-width: 769px) {
  .questionnaire-wrap {
    .header { 
      justify-content: space-between;
    }
  }
}

@media (max-width: 500px) {
  #auto {
    .wizard-wrap {
      overflow-x: auto;
    }
  }
}

@media screen and (max-width: 280px){
  .questionnaire-wrap{
    .header{
      .wizard-wrapper{
        width: 200px;
        min-width: 150px;
      }
    }
  }
}
