
          @import "@/assets/css/vars.scss";
        

.landing-nav {
  height: $nav-height;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(260.62deg,#5942E6 -0.28%, #3664D9 102.05%);
    opacity: 0;
    z-index: -1;
  }
  &.fixed {
    padding-right: min(12vw, 12em - 20px);
    padding-left: min(12vw, 12em);
    position: fixed;
    top: -2em;
    // uncomment for gap
    // top: 0;
    left: 0;
    z-index: 2;
    transition: position .3s ease-out;
    margin-top: 2em;
    .background {
      transition: opacity .3s ease-out;
      opacity: 1;
    }
  }
  .logo {
    img {
      cursor: pointer;
      width: 9.5em;
    }
  }
  .logo-text {
    color: white
  }
  .links-buttons-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // gap: 4em;
    @include flex-gap(4em, 'row wrap');
    //in-burger styles
    &.burger {
      margin-top: 0;
      background: linear-gradient(260.62deg, #5942E6 -0.28%, #3664D9 102.05%);
      padding: 5vw;
      // gap: 4vw;
      border-radius: 6px;
      * {
        font-size: 1.2em;
      }
      .links {
        flex-grow: 1;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
      }
      .buttons {
        flex-direction: column;
        @include flex-gap(1em, 'column wrap');
      }
    }
    //in-burger styles
    .links {
      display: flex;
      flex-wrap: wrap;
      // gap: 3em;
      @include flex-gap(3em, 'row wrap');
      margin-right: 1em;
      a {
        text-decoration: none;
        cursor: pointer;
        color: #FFF;
        font-weight: 600;
      }
    }
    .buttons {
      display: flex;
      // gap: 1.2em;
      @include flex-gap(1.2em, 'row nowrap');
      > * {
        width: 9em;
      }
      .login {
        border: 2px solid #FFF;
        color: #FFF;
      }
      .less-padding {
        padding: 0.8em 0.5em;
      }
      .agent {
        border: 2px solid rgba($color: #FFF, $alpha: 1);
        color: #FFF;
      }
      .agent-alt {
        border: 2px solid $main-blue;
        color: $main-blue;
      }
    }
  }
}

@media (max-width: 1160px) {
  .landing-nav {
    &.fixed {
      padding-left: 3em;
      padding-right: 3em;
    }
    .links-buttons-wrap {
      .links {
        margin-right: 1.65vw !important;
        @include flex-gap(1.65vw, 'row nowrap');
      }
    }
  }
}

@media (max-width: 870px) {
  .landing-nav {
    flex-direction: column;
    flex-wrap: unset;
    // gap: 1em;
    &.fixed {
      flex-direction: row;
      flex-wrap: wrap;
      // gap: 0;
      padding-left: 1em;
      padding-right: 1em;
    }
    .links-buttons-wrap {
      // gap: 1.3vw;
      .links {
        // gap: 1.3vw;
        @include flex-gap(3vw, 'row nowrap');
      }
    }
  }
}

@media (max-width: 768px) {
  .landing-nav {
    flex-direction: row;
    &.fixed {
      padding-left: 4em;
      padding-right: 4em;
    }
    .links-buttons-wrap {
      background: #F8F9FC !important;
      height: 100%;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      // gap: 1.65vw;
      .links {
        // gap: 1.65vw;
        flex-grow: unset !important;
         a{
          display: inline-block;
          margin: 10px 0;
          font-family: 'Inter';
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 155.8%;
          text-align: center;
          letter-spacing: -0.02em;
          color: #434961;
          // &.router-link-active {
          //   color: $main-blue;
          // }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .landing-nav {
    &.fixed {
      padding-left: 2em;
      padding-right: 2em;
    }
  }
}
