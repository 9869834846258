
          @import "@/assets/css/vars.scss";
        

.toast-wrap {
  display: flex;
  padding: 1.5em 1.2em;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  cursor: pointer;
  // background: rgba(154, 181, 255, 0.37);
  // type error is default
  background: rgba(255, 45, 45, .7);
  backdrop-filter: blur(17px);
  border-radius: 10px;
  .message {
    margin: 0;
    word-break: break-word;
  }
  &.info {
    background: $main-blue;
  }
  &.success {
    background: rgba(154, 181, 255, 1);
  }
  &.warning {
    background-color: rgb(251, 255, 0);
    color: rgb(126, 124, 0);
  }
}
