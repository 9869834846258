
          @import "@/assets/css/vars.scss";
        

.burger-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  // position: fixed;
  // background: $background-color;
  // background-image: linear-gradient(to right, #3E94FF, #886CF5);
  right: 3vw;
  // width: $notification-size;
  // height: $notification-size;
  z-index: 4;
  .burger-content {
    margin-top: 0 !important;
    margin-left: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to right, #3E94FF, #886CF5);
    // background: $background-color;
    padding: 3vw 5vw;
    width: 24em;
    border-radius: 6px;
    // gap: 4vw;
    @include flex-gap(4vw, 'column nowrap');
    // .logo {
    //   width: 10em;
    // }
    .links {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      @include flex-gap(1vw, 'column nowrap');
      a {
        font-size: 1.5em;
        text-decoration: none;
        color: #FFF;
        font-weight: 600;
        &.router-link-active {
          color: $button-color-aqua;
          img {
            filter: brightness(3);
          }
        }
      }
    }
    .input-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include flex-gap(2vw, 'column nowrap');
      label {
        color: #FFF;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include flex-gap(1.2em, 'column nowrap');
      > * {
        width: 100%;
      }
      .login {
        border: 2px solid #FFF;
        color: #FFF;
        margin: 2em 0 1em 0;
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .burger-wrap{
    .burger-content{
      width: 280px;
    }
  }
}
