
          @import "@/assets/css/vars.scss";
        

.auto-calculation {
  .form {
    .base-label-el {
      width: 100% !important;
    }
  }
}
