
          @import "@/assets/css/vars.scss";
        

.step-wrap {
  h2.title {
    display: flex;
    @include flex-gap(10px, 'row nowrap');
    margin: 0;
    margin-bottom: max(2em, 1.8vw);
    span {
      font-size: 1.4em;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    // gap: 1.7em 4em;
    @include flex-gap(1.7em, 'row wrap');
    .card {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      background: #D3E5FD4A;
      border-radius: 10px;
      padding: 1.5em 2em;
      gap: 1em;
      h3 {
        margin: 0;
        color: $main-blue;
      }
      p {
        font-family: 'Spartan';
        font-weight: 400;
        margin: 0;
        color: #5B6074;
      }
    }
    .checkbox {
      margin-top: 1em;
    }
    > * {
      width: calc(50% - 2em) !important;
    }
  }
}

@media (max-width: 1367px) {
  .step-wrap {
    .form {
      > * {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 689px) {
  .step-wrap {
    .form {
      > * {
        width: calc(100% - 2em) !important;
      }
    }
  }
}
