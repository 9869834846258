
          @import "@/assets/css/vars.scss";
        

#agent-leads {
  .modal-wrap {
    .form-wrap {
      max-width: 75%;
      min-width: 320px;
    }
  }
}
