
          @import "@/assets/css/vars.scss";
        

.badge-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // gap: .5em;
  @include flex-gap(.5em, 'column nowrap');
  // width: 9em;
  .header {
    font-size: .9em;
    font-weight: 600;
    white-space: nowrap;
    color: #656B7E;
  }
  .badges {
    display: flex;
    min-width: 200px;
    .badge {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
      background: #EFF0F1;
      border-radius: 6px;
      padding: .5em 1em;
      font-family: 'Open Sans';
      align-items: center;
      min-width: 10em;
      justify-content: space-between;
      width: 80%;
      .price {
        color: #434961;
        font-size: 1.3em;
        margin-bottom: 2px;
      }
      .type {
        color: #A8ACB8;
        font-size: .8em;
        font-weight: 600;
      }
      &.green {
        background: #C5F2F2;
        .type {
          color: #79C0C0;
        }
      }
      &.purple {
        background: #DAD5F7;
        .type {
          color: #9E95D6;
        }
      }
      &.blue {
        background: #D3E5FD;
        .type {
          color: #96ACCA;
        }
      }
    }
  }
}
