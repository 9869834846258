
          @import "@/assets/css/vars.scss";
        

.modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 1em;
  margin: 0 !important;

  top: 0;
  left: 0;
  right: 0;
  bottom: -200px;

  z-index: 4;
  backdrop-filter: blur(3px);
}
.form-wrap {
  $icon-size: 15px;
  position: relative;
  // display: flex;
  // lol ^
  // align-items: center;
  // max-width: 85%;
  max-height: calc(100vh - 2em);
  overflow: auto;
  margin-left: -$icon-size;
  margin-bottom: 200px;
  display: flex;
  align-items: flex-start;
  // overflow-y: hidden;
  .close {
    position: sticky;
    float: left;
    top: $icon-size;
    left: calc(100% - 30px);
    cursor: pointer;
    width: $icon-size;
    height: $icon-size;
  }
}

@media (max-width: 769px) {
  .form-wrap {
    max-height: 90vh;
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .form-wrap {
    .close {
      right: calc(100% - 30px);
    }
  }
}}
